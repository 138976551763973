<template>
  <div class="transactions">
    <div class="hfi-transactions-table-header uk-flex uk-flex-between">
      <h4 class="uk-margin-remove-bottom">
        <i class="fal fa-play uk-margin-xsmall-right"></i>
        <span
          class="cursor-pointer"
          uk-tooltip="title: shows transactions for your account; pos: right"
        >
          transactions
        </span>
      </h4>

      <div v-if="!loading && transactions.length > 0" class="uk-flex">
        <p
          class="uk-margin-remove-bottom uk-margin-small-right"
          style="margin-top: 2px"
        >
          per page
        </p>
        <div
          :class="`uk-button-group ${
            totalPages > 1 ? 'uk-margin-small-right' : ''
          }`"
        >
          <button
            v-for="perPageAmt in perPages"
            :class="`uk-button uk-button-small ${
              perPageAmt === perPage ? 'uk-active' : ''
            }`"
            :disabled="perPageAmt === perPage"
            @click="setPerPage(perPageAmt)"
          >
            {{ perPageAmt }}
          </button>
        </div>

        <ul v-if="totalPages > 1" class="uk-pagination">
          <li :class="currentPage < 2 ? 'uk-disabled' : ''">
            <a href="#" :disabled="currentPage < 2" @click.prevent="prevPage">
              <span uk-pagination-previous></span
            ></a>
          </li>

          <li
            :class="`${currentPage === 1 ? 'uk-active' : ''} ${
              currentPage === 1 ? 'uk-disabled' : ''
            }`"
          >
            <span v-if="currentPage === 1">1</span>
            <a v-else href="#" @click.prevent="thisPage(1)">1</a>
          </li>

          <li v-if="pages[0] > 2" class="uk-disabled"><span>&hellip;</span></li>

          <li
            v-if="totalPages > 2"
            v-for="page in pages"
            :class="`${page === currentPage ? 'uk-active' : ''} ${
              page === currentPage ? 'uk-disabled' : ''
            }`"
          >
            <span v-if="page === currentPage">{{ page }}</span>
            <a v-else href="#" @click.prevent="thisPage(page)">{{ page }}</a>
          </li>

          <li
            v-if="pages[pages.length - 1] < totalPages - 1"
            class="uk-disabled"
          >
            <span>&hellip;</span>
          </li>

          <li
            :class="`${currentPage === totalPages ? 'uk-active' : ''} ${
              currentPage === totalPages ? 'uk-disabled' : ''
            }`"
          >
            <span v-if="currentPage === totalPages">{{ totalPages }}</span>
            <a v-else href="#" @click.prevent="thisPage(totalPages)">{{
              totalPages
            }}</a>
          </li>

          <li :class="currentPage > totalPages - 1 ? 'uk-disabled' : ''">
            <a
              href="#"
              :disabled="currentPage > totalPages - 1"
              @click.prevent="nextPage"
            >
              <span uk-pagination-next></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <table
      class="uk-table uk-responsive uk-table-xs uk-table-divider uk-margin-remove-top"
    >
      <thead class="hfi-transactions-table-header hfi-border-top-remove">
        <tr>
          <th>date/time</th>
          <th>transaction</th>
          <th>from</th>
          <th>to</th>
          <th class="uk-text-right">value</th>
          <th class="uk-text-right">price</th>
        </tr>
      </thead>

      <tbody v-if="!account">
        <tr>
          <td colspan="4">no wallet connected</td>
        </tr>
      </tbody>

      <tbody v-else-if="loading">
        <tr>
          <td colspan="4">
            <vue-loaders-ball-pulse
              color="currentColor"
              scale="0.5"
              class="uk-text-middle"
              style="margin-top: -3px"
            />
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr
          v-if="!loading && transactions.length > 0"
          v-for="transaction in transactions.filter(
            (tx) => tx.page === currentPage
          )"
          :key="transaction.tx_hash"
          :class="transaction.successful ? '' : 'hfi-error'"
        >
          <td>
            {{ utcToLocal(transaction.block_signed_at) }}
          </td>

          <td>
            <i
              :class="
                'fal ' + (transaction.successful ? 'fa-check-circle' : 'fa-ban')
              "
            ></i>
            <a
              class="hfi-link"
              target="_blank"
              :href="transaction.explorerMeta.typeUrl"
              :uk-tooltip="`title: ${transaction.explorerMeta.typeMessage}; pos: right;`"
            >
              {{ transaction.tx_hash.slice(0, 6) }}...{{
                transaction.tx_hash.slice(-4)
              }}
              <i class="fal fa-external-link-square"></i>
            </a>
          </td>

          <td>
            <a
              class="hfi-link"
              target="_blank"
              :href="transaction.fromExplorerMeta.typeUrl"
              :uk-tooltip="`title: ${transaction.fromExplorerMeta.typeMessage}; pos: right;`"
            >
              {{ transaction.from_address.slice(0, 6) }}...{{
                transaction.from_address.slice(-4)
              }}
              <i class="fal fa-external-link-square"></i>
            </a>
          </td>

          <td>
            <a
              class="hfi-link"
              target="_blank"
              :href="transaction.toExplorerMeta.typeUrl"
              :uk-tooltip="`title: ${transaction.toExplorerMeta.typeMessage}; pos: right;`"
            >
              {{ transaction.to_address.slice(0, 6) }}...{{
                transaction.to_address.slice(-4)
              }}
              <i class="fal fa-external-link-square"></i>
            </a>
          </td>

          <td class="uk-text-right">
            {{ formatEther(transaction.value, 4) }}
          </td>

          <td class="uk-text-right">
            {{
              transaction.value_quote
                ? transaction.value_quote.toLocaleString(undefined, digits(2))
                : "n/a"
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { store } from "@/store";
import { ethers } from "ethers";
import { closeAllNotifications } from "@/utils/utils";
import Token from "../types/Token";
import { getTxs } from "../utils/transactions";
import moment from "moment";
import { getExplorerMeta } from "../utils/utils";
import { addEventListener, removeEventListener } from "../utils/event";
import Event from "../types/Event";

export default {
  name: "Transactions",

  data() {
    return {
      loading: true,
      transactions: [],
      totalRows: 0,
      pagesToShow: 5, // odd number
      totalPages: 0,
      currentPage: 1,
      perPage: 10,
      perPages: [10, 50, 100],
      pages: [],
      ethers,
      Token,
      formatEther: (value, digits = 2) => {
        if (value == null) return "n/a";
        return parseFloat(ethers.utils.formatEther(value)).toLocaleString(
          undefined,
          this.digits(digits)
        );
      },
    };
  },
  computed: {
    account() {
      return store.state.account;
    },
    network() {
      return store.state.network;
    },
  },

  async mounted() {
    await this.initialiseState();
  },

  beforeDestroy() {
    removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
    closeAllNotifications();
  },

  methods: {
    initialiseState: async function () {
      removeEventListener(Event.WalletUpdate, this.walletUpdateEventId);
      this.walletUpdateEventId = addEventListener(
        Event.WalletUpdate,
        this.resetState.bind(this)
      );
      await this.resetState();
    },

    resetState: async function () {
      if (
        this.$route.meta.networks &&
        !this.$route.meta.networks?.includes(this.network)
      )
        return;

      this.transactions = [];

      if (this.account) await this.showTransactions();
      else this.transactions = [];
    },

    showTransactions: async function () {
      this.transactions = await getTxs(store.state.account);
      let ix = 0;
      for (let tx of this.transactions) {
        tx.row = ix++;
        tx.explorerMeta = await this.explorerMeta("tx", tx.tx_hash);
        tx.fromExplorerMeta = await this.explorerMeta(
          "address",
          tx.from_address
        );
        tx.toExplorerMeta = await this.explorerMeta("address", tx.to_address);
        tx.page = Math.ceil(ix / this.perPage);
      }

      this.$emit("loaded", true);
      this.totalRows = this.transactions.length;
      this.totalPages = Math.ceil(this.transactions.length / this.perPage);
      this.setPageRange();
      this.loading = false;
    },

    explorerMeta(type, hash) {
      return getExplorerMeta(hash, type);
    },

    utcToLocal(value) {
      return moment.utc(value).local().format("DD/MM/YYYY HH:mm:ss");
    },

    digits(minDigits, maxDigits = minDigits) {
      return {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      };
    },

    capitalise(str) {
      const lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice(1);
    },

    nextPage() {
      this.currentPage++;
      this.setPageRange();
    },

    prevPage() {
      this.currentPage--;
      this.setPageRange();
    },

    thisPage(page) {
      this.currentPage = page;
      this.setPageRange();
    },

    setPageRange() {
      let fromPage = this.currentPage;
      let toPage = this.currentPage;
      const pagesEitherSide = (this.pagesToShow - 1) / 2;
      if (this.currentPage - pagesEitherSide < 2) {
        fromPage = 2;
      } else if (this.currentPage + pagesEitherSide > this.totalPages + 1) {
        fromPage = this.totalPages - pagesEitherSide;
      } else {
        fromPage = this.currentPage - pagesEitherSide;
      }
      toPage =
        this.currentPage < fromPage + pagesEitherSide
          ? this.currentPage + pagesEitherSide
          : fromPage + this.pagesToShow - 1 > this.totalPages - 1
          ? this.totalPages - 1
          : fromPage + this.pagesToShow - 1;
      this.pages = this.setRangeArray(fromPage, toPage);
    },

    setRangeArray(a, b) {
      // from, to
      a = b - a + 1;
      let c = [];
      while (a--) c[a] = b--;
      return c;
    },

    setPerPage(perPage) {
      this.perPage = perPage;
      this.totalPages = Math.ceil(this.transactions.length / this.perPage);
      let ix = 0;
      for (let tx of this.transactions) {
        ix++;
        tx.page = Math.ceil(ix / this.perPage);
      }
      this.currentPage = 1;
      this.setPageRange();
    },
  },
};
</script>

<style lang="scss" scoped>
@use "src/assets/styles/handle.fi" as handle;

.hfi-transactions-table-header {
  padding: 0.5rem 1rem;
  border: 2px solid handle.$green;
}

.hfi-transactions-header-row {
  padding: 0.5rem 1rem;
  border: 2px solid handle.$green !important;
}

.hfi-top-n1 {
  top: -1px;
}

.hfi-token-cell {
  width: 116px;
}

.hfi-collateral-cell {
  width: 84px;
}

.uk-table {
  border-top: unset;
}

.fa-stack {
  margin-top: -4px;
  display: inline-block;
  height: 1em;
  line-height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1.25em;
}

.fa-stack-halfx {
  font-size: 0.5em;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.hfi-action-column {
  width: 50px;
}

.hfi-button-group-button {
  padding: 0;
  margin: -6px 0 -4px;
  width: 80px;
  line-height: 28px;
}

.hfi-button-group-button-first {
  border-right: unset !important;
}

.hfi-button-group-button-not-first {
  border-right: unset !important;
}

.hfi-button-group-button-last {
}

.hfi-token-column {
  width: 250px;
}

.uk-pagination {
  margin: -4px 0;
}

.uk-button-group > button {
  border-width: 1px !important;
  font-size: 16px;
  line-height: 1.5;
  padding: 5px 10px;
  margin: -4px 0;
}

.uk-button-group button:not(:last-child) {
  border-right: none !important;
}

.uk-button-group > button.uk-active {
  color: handle.$background !important;
  background-color: handle.$green !important;
  cursor: default;
  pointer-events: none;
}
</style>
