import axios from "axios";
import { provider } from "@/utils/wallet";

const COVALENT_API_URL = "https://api.covalenthq.com/";

export const getTxs = async (account) => {
  const network = await provider.getNetwork();
  const chainId = network.chainId;
  const txApiStub = `${COVALENT_API_URL}v1/${chainId}/address/`;

  const txApiSuffix =
    "/transactions_v2/?&key=" + process.env.VUE_APP_COVALENT_API_KEY;
  const txApiTarget = txApiStub + account + txApiSuffix;

  try {
    const res = await axios.get(txApiTarget);
    if (res.status === 200) {
      return res.data.data.items;
    } else {
      console.log("getTxs Error:", res);
      throw new Error(res.status);
    }
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      console.info("getTxs Error:", error.response);
      throw new Error(error.response.data.message);
    } else if (error.request) {
      // The request was made but no response was received
      console.info("getTxs No Response:", error.request);
      throw new Error(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.info("getTx Other Error:", error.message);
      throw new Error(error);
    }
  }
};
